import React, { useEffect, useState } from "react";
import { useHeader } from "../../../../contexts/HeaderContext";
import "./AllOrders.css";
import Table from "../../../../components/Table/Table";
import apiClient from "../../../../api/apiClient";
import { useNavigate } from "react-router-dom";

function EnrollmentsCompleted() {
  const navigate = useNavigate();
  const { setHeaderTitle } = useHeader();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to format the date as per 'es-ES' locale
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("es-ES", options);
  };

  // Fetch data from API or any other source
  useEffect(() => {
    setHeaderTitle("Enrolamientos");

    const fetchData = async () => {
      try {
        const response = await apiClient.get("/enrollments", { params: { filter: { status: 1 } } });
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Error al obtener las órdenes");
        setLoading(false);
      }
    };

    fetchData();
  }, [setHeaderTitle]);

  // Defining headers for the table, matching the columns in the image
  const headers = [
    { label: "Fecha enrolamiento", accessor: "created_at", searchable: "true" },
    {
      label: "N° Factura del reseller",
      accessor: "reseller_invoice_number",
      searchable: "true",
    },
    { label: "Cliente", accessor: "customer_name", searchable: "true" },
    {
      label: "Organization ID",
      accessor: "apple_organization_id",
      searchable: "true",
    },
    {
      label: "Tipo",
      accessor: "transaction_type",
      searchable: "true",
    },
    {
      label: "Dep Transaction ID",
      accessor: "dep_transaction_id",
      searchable: "true",
    }
  ];

  if (loading) {
    return <div>Cargando órdenes...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const formattedData = data.map((order) => ({
    ...order,
    created_at: formatDate(order.created_at),
    enrollment_status: "Completed",
    error_code: order.error_code || "",
    error_message: order.error_message || ""
  }));

  const handleRowClick = (row) => {
    navigate(`/enrollments/${row.id}`);
  };

  return (
    <div>
      <div className="row layout-top-spacing">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
          <div className="widget widget-content-area br-4">
            <div className="widget-one">
              <h5 className="widget-title">Enrolamientos completados</h5>
              <Table
                data={formattedData}
                headers={headers}
                initialRowsPerPage={10}
                enableRowClick={true}
                onRowClick={handleRowClick}
                dataName={"ordenes"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnrollmentsCompleted;

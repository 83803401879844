import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import './ShowOrderDetailsAPI.css';

function OrderDetailsModal({ show, handleClose }) {
    return (
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="modalTitle" centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title id="modalTitle" style={{ color: '#000' }}>
                    Detalles de la orden ORD124 (API)
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Tabla de detalles de la orden */}
                <Table id="orderTableModal" className="table">
                    <thead>
                        <tr>
                            <th>Fecha de Orden</th>
                            <th>Tipo de Orden</th>
                            <th>ID de Cliente</th>
                            <th>Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>02/01/2024</td>
                            <td>OR</td>
                            <td>C457</td>
                            <td>COMPLETE</td>
                        </tr>
                    </tbody>
                </Table>

                {/* Tabla de detalles de los dispositivos */}
                <Table id="orderTableModal" className="table">
                    <thead>
                        <tr>
                            <th>ID de Dispositivo</th>
                            <th>Etiqueta de Activo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>SF234890</td>
                            <td>Apple iPhone 15 Pro 512GB Black Titanium</td>
                        </tr>
                        <tr>
                            <td>SF895601</td>
                            <td>iPhone 14 Pro 512GB Deep Purple</td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer id="orderModalFooter">
                <Button variant="primary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default OrderDetailsModal;

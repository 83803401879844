import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useHeader } from "../../../../contexts/HeaderContext";
import apiClient from '../../../../api/apiClient';
import FileUploader from "../../../../components/FileUploader/FileUploader";
import { useAuth } from '../../../../contexts/AuthProvider';
import Swal from 'sweetalert2';

function DataResellerVerification(company) {
    const { setHeaderTitle } = useHeader();
    const { userInfo } = useAuth();

    const [formData, setFormData] = useState({
        clientName: company.company.name,
        resellerId: company.company.apple_reseller_id,
    });

    const [selectedFile, setSelectedFile] = useState(null);

    const fieldNamesDictionary = {
        "path_print": "Archivo adjunto",
        "name": "Razón social",
        "apple_reseller_id": "Apple Reseller ID",
    };

    useEffect(() => {
        setHeaderTitle("Verificación de datos");
    }, [setHeaderTitle]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleFileChange = (file) => {
        setSelectedFile(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedFile) {
            Swal.fire({
                icon: 'error',
                title: 'Archivo requerido',
                text: 'Por favor adjunte un archivo antes de enviar el formulario.',
            });
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.clientName);
        formDataToSend.append("apple_reseller_id", formData.resellerId);

        if (selectedFile) {
            formDataToSend.append("path_print", selectedFile);
        }

        try {
            const response = await apiClient.post(`/upload-image/${userInfo.company.id}`, formDataToSend, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            Swal.fire({
                icon: 'success',
                title: 'Formulario enviado',
                text: 'Formulario enviado con éxito.',
            }).then(() => {
                window.location.reload();
            });

        } catch (error) {
            if (error.response && error.response.data.errors) {
                const errors = error.response.data.errors;
                let errorMessages = "";

                for (const key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        const friendlyFieldName = fieldNamesDictionary[key] || key;
                        errorMessages += `${friendlyFieldName}: ${errors[key].join(', ')}\n`;
                    }
                }

                Swal.fire({
                    icon: 'error',
                    title: 'Error en el formulario',
                    text: errorMessages,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un problema al enviar el formulario. Por favor, inténtalo de nuevo más tarde.',
                });
            }

            console.error("Error al enviar:", error);
        }
    };

    const renderTooltip = (message) => (
        <Tooltip id="button-tooltip" className="rounded">{message}</Tooltip>
    );

    return (
        <div>
            <div className="row layout-top-spacing">
                <div className="col-md-9 col-12 layout-spacing">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one col-8">
                            <p>
                                Antes de poder enviar la primera solicitud de enrolamiento, es necesario que valide sus
                                datos como revendedor autorizado de Apple. Por favor, complete la siguiente información,
                                la cual será verificada y aprobada por un representante de StarTech.
                            </p>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group controlId="clientName">
                                            <Form.Label>Razón social</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={formData.clientName}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group controlId="resellerId">
                                            <Form.Label>Apple Reseller ID</Form.Label>
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={renderTooltip("Corresponde al Device Enrollment Reseller ID.")}
                                            >
                                                <Form.Control
                                                    type="text"
                                                    value={formData.resellerId}
                                                    onChange={handleInputChange}
                                                />
                                            </OverlayTrigger>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <p>
                                            Por favor haga click en "Examinar" para adjuntar una captura de pantalla de Apple Partner Web o del correo de bienvenida de Apple, en donde se visualice su número de Device Enrollment Reseller ID.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FileUploader onFileSelect={handleFileChange} acceptedFileTypes=".png,.jpg,.jpeg,.gif" /> {/* Pasar la función para manejar el archivo */}
                                    </Col>
                                </Row>

                                <Button type="submit" className="btn btn-primary mt-3">Enviar verificación</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataResellerVerification;

// RoleSelect.js
import React, { useEffect, useState } from "react";
import Select from "react-select";
import apiClient from "../../../api/apiClient";

function RoleSelect({ name, onChange, readLevel, value }) {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (readLevel !== null) {
            const fetchRoles = async () => {
                setLoading(true);
                try {
                    const response = await apiClient.get("/roles/");
                    const filteredRoles = response.data.filter(role => parseInt(role.read_level) === readLevel);
                    setRoles(filteredRoles);
                } catch (error) {
                    console.error("Error al obtener los roles", error);
                } finally {
                    setLoading(false);
                }
            };
            fetchRoles();
        } else {
            setRoles([]);  // Limpiar roles si no hay un readLevel válido
        }
    }, [readLevel]);

    if (loading) {
        return <div>Cargando roles...</div>;
    }

    const options = roles.map(role => ({
        value: role.id,
        label: role.name
    }));

    return (
        <Select
            name={name}
            value={options.find(option => option.value === value)} // Establecer el valor seleccionado
            options={options}
            onChange={(selectedOption) => onChange(selectedOption, { name })}
            isDisabled={!readLevel}
            placeholder="Selecciona un rol"
            isClearable
            isSearchable
        />
    );
}


export default RoleSelect;

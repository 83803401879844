import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/img/startech-isologo_190.png";
import "./Sidebar.css";
import { useAuth } from '../../contexts/AuthProvider';
import { useSidebar } from '../../contexts/SidebarContext';
import Icon from '../Icon/Icon';

const Sidebar = () => {
    const { isLoggedIn, userInfo, logout } = useAuth();
    const location = useLocation();
    const { isSidebarOpen } = useSidebar(); // Obtener el estado del sidebar

    const handleEndSession = () => {
        logout();
    };

    const getReadLevel = () => (userInfo ? parseInt(userInfo.role.read_level, 10) : 0);

    const readLevel = getReadLevel();

    // Definir los elementos del menú de acuerdo con el nivel de acceso y el estado de sesión del usuario
    const menuItems = [
        ...(isLoggedIn ? [
            {
                heading: userInfo ? `${userInfo.full_name} - ${userInfo.company.name}` : "StarTech",
                links: [
                    {
                        icon: "logOut",
                        label: "Salir",
                        action: handleEndSession,
                    },
                ],
            },
        ] : []),
        ...(readLevel === 2 ? [
            {
                heading: "Gestión de revendedores",
                links: [
                    {
                        icon: "edit",
                        label: "Aprobaciones pendientes",
                        link: "/pending-approvals",
                    },
                    {
                        icon: "briefcase",
                        label: "Administrar revendedores",
                        link: "/resellers",
                    },
                ],
            },
            {
                heading: "Gestión de usuarios",
                links: [
                    {
                        icon: "users",
                        label: "Administrar usuarios",
                        link: "/users",
                    },
                ],
            },
        ] : []),
        ...(readLevel >= 1 ? [
            {
                heading: "Enrolamientos",
                links: [
                    {
                        icon: "layers",
                        label: "Sin enrolar",
                        link: "/awaiting",
                    }, {
                        icon: "list",
                        label: "Todas las órdenes",
                        link: "/all-orders",
                    },

                    {
                        icon: "clock",
                        label: "En progreso",
                        link: "/enrollments/in-progress",
                    },
                    {
                        icon: "checkCircle",
                        label: "Completadas",
                        link: "/enrollments/completed",
                    },
                    {
                        icon: "alertCircle",
                        label: "Completadas con error",
                        link: "/enrollments/completed-with-errors",
                    },
                    {
                        icon: "xCircle",
                        label: "Erróneas",
                        link: "/enrollments/erroneous",
                    },
                ],
            },
        ] : []),
    ];

    return (
        <div key={isLoggedIn ? "logged-in" : "logged-out"} className={`sidebar-wrapper sidebar-theme ${isSidebarOpen ? "open" : ""}`}>
            <nav id="sidebar">
                <ul className="navbar-nav theme-brand flex-row text-center">
                    <li className="nav-item theme-text">
                        <Link to="/" className="nav-link">
                            <img src={logo} alt="StarTech Logo" />
                        </Link>
                    </li>
                </ul>
                {isLoggedIn && (
                    <ul className="list-unstyled menu-categories ps">
                        {menuItems.map((item, index) => (
                            <React.Fragment key={index}>
                                <li className={`menu ${item.links && item.links.length > 0 ? "menu-heading" : ""}`}>
                                    {item.heading && (
                                        <div className="heading">
                                            <Icon icon={item.icon} />
                                            <span>{item.heading}</span>
                                        </div>
                                    )}
                                </li>
                                {item.links.map((subItem, subIndex) => (
                                    <li
                                        key={subIndex}
                                        className={`menu ${location.pathname === subItem.link ? "active" : ""}`}
                                    >
                                        {subItem.link ? (
                                            <Link to={subItem.link} className="dropdown-toggle">
                                                <div>
                                                    <Icon icon={subItem.icon} />
                                                    <span>{subItem.label}</span>
                                                </div>
                                            </Link>
                                        ) : (
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault(); // Prevenir navegación por defecto
                                                    subItem.action(); // Ejecutar logout
                                                }}
                                                className="dropdown-toggle"
                                            >
                                                <div>
                                                    <Icon icon={subItem.icon} />
                                                    <span>{subItem.label}</span>
                                                </div>
                                            </a>
                                        )}
                                    </li>
                                ))}
                            </React.Fragment>
                        ))}
                    </ul>
                )}
            </nav>
        </div>
    );
};

export default Sidebar;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Button } from 'react-bootstrap';
import './OrderActions.css';
import { useAuth } from '../../../../../../contexts/AuthProvider';
import OrderDetailsModal from "../Modals/ShowOrderDetailsAPI/ShowOrderDetailsAPI";
import Swal from "sweetalert2";

const OrderActions = ({ orderStatus, orderType = "OR", remainingDevices, idOrder }) => {

    const navigate = useNavigate();
    const { userInfo } = useAuth();
    const { read_level, execute, read } = userInfo.role || {};
    const isAdmin = execute && read;
    const isUser = !isAdmin;
    const [showModal, setShowModal] = useState(false);


    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    // Asegurarse de que orderStatus es un número
    const parsedOrderStatus = parseInt(orderStatus, 10);
    const readLevelInt = parseInt(read_level, 10);


    // Lógica para el estado de la orden
    const isOrderInProgress = parsedOrderStatus === null || parsedOrderStatus === undefined || parsedOrderStatus === 0;
    const isOrderCompleted = parsedOrderStatus === 1 || parsedOrderStatus === 2;
    const isOrderError = parsedOrderStatus === 3;

    // Lógica para el tipo de orden
    const isOR = orderType === "OR";
    const isRE = orderType === "RE";
    const isVD = orderType === "VD";
    const isOV = orderType === "OV";

    // Mostrar botón para detalles de la orden para Startech (read_level === 2) y solo si la orden no está en progreso
    const canShowDetailsButton = parseInt(read_level, 10) === 2 && !isOrderInProgress;

    // Mostrar opciones de dropdown basadas en el estado de la orden y tipo de orden para Startech
    const canShowDropdownOptions = isOrderCompleted;

    // Mostrar solo la opción "Retornar" para Revendedor si la orden está completada o completada con errores
    const canShowReturn = isOrderCompleted;

    // Estilos condicionales para las opciones deshabilitadas
    const disabledStyle = { textDecoration: 'line-through', color: '#999', pointerEvents: 'none' };
    const enabledStyle = { color: '#000' };

    // Lógica para deshabilitar "Retornar (RE)"
    const isReturnDisabled = isRE || !remainingDevices || !canShowReturn;

    const handleRedirect = (path) => {
        navigate(path);
    };

    const handleCancelOrder = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "Esta acción anulará la orden. No podrás revertir este cambio.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, anularla',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                // Aquí puedes hacer la lógica de anulación, como llamar a un API
                Swal.fire('Anulada', 'La orden ha sido anulada.', 'success');
            }
        });
    };

    return (
        <>
            <div className="col-8 d-flex align-items-center justify-content-end p-0">
                <div className="d-flex align-items-center justify-content-end">
                    {/* Si es Startech y se puede mostrar el botón de detalles */}
                    {canShowDetailsButton && (
                        <>
                            <Button variant="outline-startech" onClick={handleShow}>
                                Mostrar detalles de la orden (API)
                            </Button>
                            <OrderDetailsModal show={showModal} handleClose={handleClose} />
                        </>
                    )}

                    {/* Dropdown de acciones solo para roles Admin, no para Usuario */}
                    {!isUser && (
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="btn-outline-startech"
                                id="dropdown-basic"
                                className="btn-outline-startech ml-2"
                            >
                                Acciones sobre la órden <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down">
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {/* Lógica para Startech - Opción Modificar (OV) */}
                                {readLevelInt === 2 && (
                                    <>
                                        <Dropdown.Item
                                            as="button"
                                            onClick={() => handleRedirect(`/enrollments/${idOrder}/override`)}
                                            disabled={!canShowDropdownOptions || !isOR}
                                            style={canShowDropdownOptions && isOR ? enabledStyle : disabledStyle}
                                        >
                                            <i className="flaticon-gear-fill mr-1"></i>
                                            <span style={!canShowDropdownOptions || !isOR ? disabledStyle : {}}>
                                                Modificar (OV)
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            as="button"  // Usamos "button" en lugar de Link
                                            onClick={handleCancelOrder}  // Ejecuta SweetAlert antes de anular
                                            disabled={!canShowDropdownOptions || !isOR}
                                            style={canShowDropdownOptions && isOR ? enabledStyle : disabledStyle}
                                        >
                                            <i className="flaticon-gear-fill mr-1"></i>
                                            <span style={!canShowDropdownOptions || !isOR ? disabledStyle : {}}>
                                                Anular (VD)
                                            </span>
                                        </Dropdown.Item>
                                    </>
                                )}


                                {/* Opción única de Retornar (RE) para Startech y Revendedor */}
                                <Dropdown.Item
                                    as="button"
                                    onClick={() => handleRedirect(`/enrollments/${idOrder}/return`)}
                                    disabled={isReturnDisabled}
                                    style={!isReturnDisabled ? enabledStyle : disabledStyle}
                                >
                                    <i className="flaticon-bell-fill-2 mr-1"></i>
                                    <span style={isReturnDisabled ? disabledStyle : {}}>
                                        Retornar (RE)
                                    </span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
            </div>
        </>
    );
};

export default OrderActions;

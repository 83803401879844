import React, { useEffect, useState } from "react";
import "../../../../assets/css/apps/invoice-preview.css";
import './Order.css';
import OrderActions from "./components/OrderActions/OrderActions";
import OrderProducts from "./components/OrderProducts/OrderProducts";
import TransactionTable from "./components/TranactionTable/TransactionTable";
import { useHeader } from "../../../../contexts/HeaderContext";
import apiClient from "../../../../api/apiClient";
import { useParams } from 'react-router-dom';
import { formatDate } from "../../../../utils/dates";
import Badge from "../../../../components/Badge/Badge";

const Order = () => {
    const { id } = useParams();
    const { setHeaderTitle } = useHeader();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setHeaderTitle("Orden de ");
    }, [setHeaderTitle]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get(`/enrollment-full-detail/${id}`);
                setData(response.data);
                setLoading(false);
            } catch (err) {
                setError("Error al obtener las órdenes");
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Calcula si hay dispositivos "enrolled" (remainingDevices)
    const remainingDevices = data.devices && data.devices.some(device => device.enrolled === 1);

    return (
        <div>
            <div className="row invoice layout-top-spacing layout-spacing">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="doc-container">
                        <div className="row mb-4">
                            <div className="col-xl-9">
                                <div className="invoice-container">
                                    <div className="invoice-inbox">
                                        <div id="ct">
                                            <div className="invoice-00001">
                                                <div className="content-section">
                                                    <div
                                                        className="inv--head-section inv--detail-section"
                                                        style={{
                                                            margin: 0,
                                                            borderBottom: "none",
                                                            paddingTop: "21px",
                                                        }}
                                                    >
                                                        <div className="row">
                                                            <div
                                                                className="col-sm-7 text-sm-left"
                                                                style={{ paddingLeft: 0 }}
                                                            >
                                                                <p className="inv-list-number">
                                                                    <span className="inv-title" style={{ color: "#000" }}>
                                                                        {data.customer_name}
                                                                    </span>
                                                                </p>
                                                                <p className="inv-email-address customer_font_size">
                                                                    Apple Organization ID {data.apple_organization_id}
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="col-sm-5 text-sm-end"
                                                                style={{ marginTop: "10px", paddingRight: "0px" }}
                                                            >
                                                                <p className="inv-list-number">
                                                                    <span className="inv-title" style={{ color: "#000" }}>
                                                                        Orden Nº {data.reseller_invoice_number}
                                                                    </span>
                                                                </p>
                                                                <p className="inv-list-number">
                                                                    <span className="inv-title" style={{ fontSize: "1em" }}>
                                                                        {formatDate(data.date)}
                                                                    </span>
                                                                </p>
                                                                <p className="inv-list-number">
                                                                    <span className="inv-title" style={{ fontSize: "1em" }}>
                                                                        <span style={{ color: "#000" }}>Fecha de envío:</span> {formatDate(data.delivery_date)}
                                                                    </span>
                                                                </p>
                                                                <p className="inv-list-number">
                                                                    <span className="inv-title" style={{ fontSize: "1em" }}>
                                                                        <span style={{ color: "#000" }}>Número de envío:</span> {data.delivery_number}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <hr style={{ marginTop: "5px", marginBottom: "10px" }} />
                                                        <div className="row">
                                                            <div className="col-4 text-left">
                                                                <div
                                                                    style={{
                                                                        display: "inline-block",
                                                                        borderRadius: "5px",
                                                                    }}
                                                                >
                                                                    <div style={{ color: "#000", fontWeight: 600 }}>
                                                                        Estado general de la órden
                                                                    </div>
                                                                    <Badge
                                                                        status={data.status}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* Pasar status y tipo de orden al componente OrderActions */}
                                                            <OrderActions
                                                                orderStatus={data.status}
                                                                orderType={data.transactions[0]?.transaction_type}
                                                                remainingDevices={remainingDevices}
                                                                idOrder={id}
                                                            />
                                                        </div>
                                                    </div>

                                                    <OrderProducts products={Array.isArray(data.devices) ? data.devices : []} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TransactionTable orderId={id} transactions={data.transactions} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Order;
import React, { useEffect } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { useAuth } from '../../contexts/AuthProvider';
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute";
import Login from "../../modules/public/pages/Login/Login";
import Home from "../../modules/shared/pages/Home/Home";
import PendingApprovals from '../../modules/internal/pages/PendingApprovals/PendingApprovals';
import ManageReseller from '../../modules/internal/pages/ManageReseller/ManageReseller';
import ManageUsers from '../../modules/internal/pages/ManageUsers/ManageUsers';
import AllOrders from '../../modules/shared/pages/AllOrders/AllOrders';
import AwaitingToEnroll from '../../modules/internal/pages/AwaitingToEnroll/AwaitingToEnroll';
import EditReseller from '../../modules/internal/pages/EditReseller/EditReseller';
import Order from '../../modules/shared/pages/Order/Order';
import TransactionDetail from '../../modules/internal/pages/TransactionDetail/TransactionDetail';
import LostPassword from '../../modules/public/pages/LostPassword/LostPassword';
import SetPassword from '../../modules/public/pages/SetPassword/SetPassword';
import PageNotFound from '../../modules/public/pages/PageNotFound/PageNotFound';
import EnrollmentsInProgress from '../../modules/shared/pages/EnrollmentsInProgress/EnrollmentsInProgress';
import EnrollmentsCompleted from '../../modules/shared/pages/EnrollmentsCompleted/EnrollmentsCompleted';
import EnrollmentsCompletedWithError from '../../modules/shared/pages/EnrollmentsCompletedWithError/EnrollmentsCompletedWithError';
import EnrollmentsErroneous from '../../modules/shared/pages/EnrollmentsErroneous/EnrollmentsErroneous';
import NewEnrollment from '../../modules/shared/pages/NewEnrollment/NewEnrollment';
import EditUserForm from '../../modules/internal/pages/EditUser/EditUser';
import { useSidebar } from '../../contexts/SidebarContext';
import OrderOverride from '../../modules/shared/pages/OrderOverride/OrderOverride';
import OrderReturn from "../../modules/shared/pages/OrderReturn/OrderReturn";

const Layout = () => {
    const { isLoggedIn, userInfo } = useAuth();
    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const navigate = useNavigate();

    // useEffect para manejar el estado de la barra lateral
    useEffect(() => {
        const container = document.getElementById('container');
        if (container) {
            if (isSidebarOpen) {
                container.classList.add('sbar-open');
            } else {
                container.classList.remove('sbar-open');
            }
        }

        // Limpieza al desmontar el componente
        return () => {
            if (container) {
                container.classList.remove('sbar-open');
            }
        };
    }, [isSidebarOpen]);

    const handleContentClick = () => {
        if (isSidebarOpen) {
            toggleSidebar(); // Usar la función de alternar para cerrar la barra lateral si está abierta
        }
    };

    // Verificar si el usuario está autenticado y tiene restricciones específicas
    const isRestrictedUser = isLoggedIn && parseInt(userInfo?.role?.read_level, 10) === 1 && !userInfo?.company?.can_enroll;
    const hasExecutePermission = userInfo?.role?.execute;

    return (
        <>
            <Header />
            <div className="main-container" id="container">
                <Sidebar />
                <div id="content" className="main-content" onClick={handleContentClick}>
                    <div className="layout-px-spacing">
                        <Routes>
                            {/* Rutas públicas */}
                            <Route path="/login" element={<Login />} />
                            <Route path="/lost-password" element={<LostPassword />} />
                            <Route path="/users/new-password/:token" element={<SetPassword />} />

                            {/* Ruta raíz - Redirigir al login si no está autenticado */}
                            <Route path="/" element={
                                isLoggedIn ? (
                                    isRestrictedUser ? <Navigate to="/awaiting" /> : <Navigate to="/home" />
                                ) : <Navigate to="/login" />
                            } />

                            {/* Si el usuario está restringido, redirigir todas las rutas a /awaiting */}
                            {isRestrictedUser && (
                                <>
                                    <Route path="/awaiting" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <AwaitingToEnroll />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="*" element={<Navigate to="/awaiting" />} />
                                </>
                            )}

                            {/* Rutas para usuarios normales */}
                            {!isRestrictedUser && isLoggedIn && (
                                <>
                                    <Route path="/home" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <Home />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/pending-approvals" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <PendingApprovals />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/resellers" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <ManageReseller />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/resellers/:id" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <EditReseller />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/users" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <ManageUsers />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/users/:id" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn} requireExecute={true} hasExecute={hasExecutePermission}>
                                            <EditUserForm />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/all-orders" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <AllOrders />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/awaiting" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <AwaitingToEnroll />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/enrollments/in-progress" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <EnrollmentsInProgress />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/enrollments/completed" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <EnrollmentsCompleted />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/enrollments/completed-with-errors" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <EnrollmentsCompletedWithError />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/enrollments/erroneous" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <EnrollmentsErroneous />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/enrollments/new" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn} requireExecute={true} hasExecute={hasExecutePermission}>
                                            <NewEnrollment />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/enrollments/:id" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <Order />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/enrollments/:id/override" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn} requireExecute={true} hasExecute={hasExecutePermission}>
                                            <OrderOverride />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/enrollments/:id/return" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn} requireExecute={true} hasExecute={hasExecutePermission}>
                                            <OrderReturn />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="/enrollment/:id/transaction-detail/:transactionId" element={
                                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                                            <TransactionDetail />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="*" element={<PageNotFound />} />
                                </>
                            )}

                            {/* Ruta 404 si el usuario intenta acceder a una ruta no encontrada sin estar autenticado */}
                            {!isLoggedIn && (
                                <Route path="*" element={<Navigate to="/login" />} />
                            )}
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;

import Badge from "../../../../../../components/Badge/Badge";

const OrderProducts = ({ products }) => {
    return (
        <div className="inv--product-table-section" style={{ padding: 0 }}>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <span style={{ color: "#000" }}>SKU</span>
                            </th>
                            <th scope="col">
                                <span style={{ color: "#000" }}>Artículo</span>
                            </th>
                            <th scope="col">
                                <span style={{ color: "#000" }}>Serie</span>
                            </th>
                            <th>
                                <span style={{ color: "#000" }}>Estado</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {products && products.map((product, index) => (
                            <tr key={index}>
                                <td>{product.sku}</td>
                                <td>{product.description}</td>
                                <td>{product.serial}</td>
                                <td><Badge status={product.enrolled} />{ }</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrderProducts;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../../components/Table/Table";
import { Form, Row, Col, } from "react-bootstrap";
import { useHeader } from "../../../../contexts/HeaderContext";
import apiClient from "../../../../api/apiClient";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

function EditableInput({ value, onChange }) {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #ccc",
                padding: "5px",
                borderRadius: "4px",
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ marginRight: "10px" }}
            >
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
            </svg>
            <input
                type="text"
                value={value}
                onChange={onChange}
                style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "18px",
                }}
            />
        </div>
    );
}

function OrderOverride() {
    const { id } = useParams();
    const { setHeaderTitle } = useHeader();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        customer_name: "",
        apple_organization_id: "",
        date: "",
        reseller_invoice_number: "",
        delivery_date: "",
        delivery_number: "",
    });

    const [formErrors, setFormErrors] = useState({});
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Start loading state
                const response = await apiClient.get(`/enrollment-full-detail/${id}`);

                setData(response.data);

                // Convert the dates to "YYYY-MM-DD" format if necessary
                const formatDate = (dateString) => {
                    const date = new Date(dateString);
                    const year = date.getFullYear();
                    const month = (`0${date.getMonth() + 1}`).slice(-2); // Add leading zero if necessary
                    const day = (`0${date.getDate()}`).slice(-2); // Add leading zero if necessary
                    return `${year}-${month}-${day}`;
                };

                // Update form values with the fetched data
                setFormData({
                    customer_name: response.data.customer_name || "",
                    apple_organization_id: response.data.apple_organization_id || "",
                    date: response.data.date ? formatDate(response.data.date) : "",
                    reseller_invoice_number: response.data.reseller_invoice_number || "",
                    delivery_date: response.data.delivery_date ? formatDate(response.data.delivery_date) : "",
                    delivery_number: response.data.delivery_number || "",
                });

                // Preselect only the ids of devices where `enrolled` is 1
                const preselectedDeviceIds = response.data.devices
                    .filter(device => device.enrolled === 1)
                    .map(device => device.id); // Map to device ids

                setSelectedDevices(preselectedDeviceIds); // Set preselected device ids

                setLoading(false); // End loading state
            } catch (err) {
                setError("Error al obtener las órdenes");
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);


    useEffect(() => {
        setHeaderTitle("Enrolamientos");
    }, [setHeaderTitle]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [id]: value }));
    };

    const handleCustomerNameChange = (e) => {
        setFormData((prevData) => ({ ...prevData, customer_name: e.target.value }));
    };

    const isFormValid = () => {
        let errors = {};
        const {
            customer_name,
            apple_organization_id,
            date,
            reseller_invoice_number,
            delivery_date,
            delivery_number,
        } = formData;

        if (!customer_name) errors.customer_name = "Este campo es obligatorio.";
        if (!apple_organization_id) errors.apple_organization_id = "Este campo es obligatorio.";
        if (!date) errors.date = "Este campo es obligatorio.";
        if (!reseller_invoice_number) errors.reseller_invoice_number = "Este campo es obligatorio.";
        if (!delivery_date) errors.delivery_date = "Este campo es obligatorio.";
        if (!delivery_number) errors.delivery_number = "Este campo es obligatorio.";

        if (delivery_date && date && new Date(delivery_date) < new Date(date)) {
            errors.delivery_date = "La fecha de envío debe ser igual o posterior a la fecha de venta.";
        }

        if (selectedDevices.length === 0) {
            errors.selectedDevices = "Debe seleccionar al menos un dispositivo.";
        }

        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            Swal.fire({
                icon: "warning",
                title: "Formulario incompleto",
                text: "Por favor, corrija los errores en el formulario antes de continuar.",
            });
        }

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
        if (!isFormValid()) return;

        const enrollmentData = {
            ...formData,
            ids_devices: selectedDevices.map((device) => parseInt(device.id, 10)),
        };

        try {
            const response = await apiClient.post("/new-order-enrollment", enrollmentData);
            Swal.fire({
                icon: "success",
                title: "Solicitud enviada",
                text: "Solicitud de enrolamiento enviada con éxito",
            });

            navigate(`/enrollments/${response.data.enrollment.id}`);
        } catch (error) {
            console.error("Error al enviar el enrolamiento", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: `${error.response.data.error}`,
            });
        }
    };

    const headers = [
        { label: "Enrolado", accessor: "enroled", searchable: true },
        { label: "SKU", accessor: "sku", searchable: true },
        { label: "Artículo", accessor: "description", searchable: true },
        { label: "Serie", accessor: "serial", searchable: true },
        { label: "Estado", accessor: "status", searchable: true },
    ];

    const fieldNames = {
        customer_name: "Razón social del cliente final",
        apple_organization_id: "Apple Organization ID del cliente final",
        date: "Fecha de la venta",
        reseller_invoice_number: "Número de comprobante de venta",
        delivery_date: "Fecha de envío",
        delivery_number: "Número de envío",
        selectedDevices: "Selección de dispositivos",
    };

    const headersToEnroll = [
        { label: "Venta Startech", accessor: "enroled", searchable: true },
        { label: "SKU", accessor: "sku", searchable: true },
        { label: "Artículo", accessor: "description", searchable: true },
        { label: "Serie", accessor: "serial", searchable: true },
        { label: "Estado", accessor: "status", searchable: true },
    ];
    return (
        <div>
            <div className="row layout-top-spacing layout-spacing">
                <div className="col-md-9 col-12">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <div className="d-flex justify-content-between align-items-center w-100 mb-4">
                                <h5 className="widget-title mb-0">Modificar enrolamiento (OV)</h5>
                                <h5 className="mb-0">Orden Nº {data.reseller_invoice_number}</h5>
                            </div>
                            <Col>
                                <Form className="mb-5">
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group as={Row} controlId="customer_name" className="mb-2">
                                                <Col sm={10}>
                                                    <EditableInput
                                                        value={formData.customer_name}
                                                        onChange={handleCustomerNameChange}
                                                    />
                                                </Col>

                                                {formErrors.customer_name && (
                                                    <small className="text-danger">{formErrors.customer_name}</small>
                                                )}
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="apple_organization_id" className="mb-2">
                                                <Form.Label column sm={4} className="d-flex align-items-center">
                                                    Apple Organization ID
                                                </Form.Label>
                                                <Col sm={6}>
                                                    <EditableInput
                                                        value={formData.apple_organization_id}
                                                        onChange={handleInputChange}
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Col>


                                        <Col md={6}>
                                            <Form.Group as={Row} controlId="date" className="mb-2 justify-content-end">
                                                <Form.Label column sm={4} className="d-flex align-items-center">
                                                    Fecha de la orden:
                                                </Form.Label>
                                                <Col sm={6}>
                                                    <Form.Control
                                                        type="date"
                                                        value={formData.date}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    {formErrors.date && <small className="text-danger">{formErrors.date}</small>}
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="delivery_date" className="mb-2 justify-content-end">
                                                <Form.Label column sm={4} >
                                                    Fecha de envío:
                                                </Form.Label>
                                                <Col sm={6}>
                                                    <Form.Control
                                                        type="date"
                                                        value={formData.delivery_date}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    {formErrors.delivery_date && (
                                                        <small className="text-danger">{formErrors.delivery_date}</small>
                                                    )}
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="delivery_number" className="mb-2 justify-content-end">
                                                <Form.Label column sm={4} >
                                                    Número de envío:
                                                </Form.Label>
                                                <Col sm={6}>
                                                    <EditableInput
                                                        value={formData.delivery_number}
                                                        onChange={(e) =>
                                                            setFormData((prevData) => ({ ...prevData, delivery_number: e.target.value }))
                                                        }
                                                    />
                                                    {formErrors.delivery_number && (
                                                        <small className="text-danger">{formErrors.delivery_number}</small>
                                                    )}
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                                <Table
                                    data={data.devices}
                                    headers={headers}
                                    enableCheckbox={true}
                                    initialRowsPerPage={10}
                                    onSelectedRowsChange={setSelectedDevices}
                                    selectedRows={selectedDevices} // Correcto, aquí pasamos los ids preseleccionados
                                    dataName={"dispositivos"}
                                />

                                {formErrors.selectedDevices && (
                                    <small className="text-danger">{formErrors.selectedDevices}</small>
                                )}

                                <hr />
                                <div class="row">
                                    <h5>Enrolar dispositivos adicionales en esta orden</h5>
                                </div>
                                <div class="row">
                                    <p> Seleccione los dispositivos no enrolados que desea
                                        enrolar en esta orden</p>
                                </div>
                                <Table
                                    data={data.devices}
                                    headers={headersToEnroll}
                                    enableCheckbox={true}
                                    initialRowsPerPage={10}
                                    onSelectedRowsChange={setSelectedDevices}
                                    selectedRows={selectedDevices} // Correcto, aquí pasamos los ids preseleccionados
                                    dataName={"dispositivos"}
                                />


                                {formErrors.selectedDevices && (
                                    <small className="text-danger">{formErrors.selectedDevices}</small>
                                )}
                            </Col>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row layout-top-spacing">
                <div className="col-md-9 col-12 layout-spacing">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <h5 className="widget-title">Enviar solicitud de modificación (OV)</h5>
                            <p>
                                Verifique los datos ingresados y presione el botón "Solicitar
                                modificación" para comenzar el proceso de desenrolamiento
                            </p>
                            <button className="btn btn-primary" onClick={handleSubmit}>
                                Solicitar modificación
                            </button>

                            {Object.keys(formErrors).length > 0 && (
                                <div className="mt-4 alert alert-danger">
                                    <ul>
                                        {Object.entries(formErrors).map(([key, error]) => (
                                            <li key={key}>{`${fieldNames[key]} - ${error}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderOverride;

import React, { useEffect, useState } from "react";
import { useHeader } from "../../../../contexts/HeaderContext";
import './AllOrders.css';
import Table from "../../../../components/Table/Table";
import apiClient from "../../../../api/apiClient";
import { useNavigate } from "react-router-dom";
import { getStatusText } from "../../../../utils/status";

function AllOrders() {
  const { setHeaderTitle } = useHeader();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  };

  useEffect(() => {
    setHeaderTitle("Enrolamientos");

    const fetchData = async () => {
      try {
        const response = await apiClient.get("/list-enrollments-status");
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Error al obtener las órdenes");
        setLoading(false);
      }
    };

    fetchData();
  }, [setHeaderTitle]);

  const headers = [
    { label: "Fecha Enrolamiento", accessor: "created_at", searchable: "true" },
    { label: "Revendedor", accessor: "company_name", searchable: "true" },
    { label: "N° Factura Startech", accessor: "reseller_invoice_number", searchable: "true" },
    { label: "Apple Reseller ID", accessor: "apple_organization_id", searchable: "true" },
    { label: "N° Factura del reseller", accessor: "reseller_invoice_number", searchable: "true" },
    { label: "Cliente", accessor: "customer_name", searchable: "true" },
    { label: "Organization ID", accessor: "apple_organization_id", searchable: "true" },
    { label: "Tipo", accessor: "transaction_type", searchable: "true" },
    { label: "DEP Transaction ID", accessor: "dep_transaction_id", searchable: "true" },
    { label: "Estado", accessor: "status", searchable: "true" },
  ];

  const handleRowClick = (row) => {
    navigate(`/enrollments/${row.id}`);
  };

  if (loading) {
    return <div>Cargando órdenes...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="row layout-top-spacing">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
          <div className="widget widget-content-area br-4">
            <div className="widget-one">
              <h5 className="widget-title">Todas las órdenes</h5>
              <Table
                data={data.map(order => ({
                  ...order,
                  created_at: formatDate(order.created_at),
                  delivery_date: formatDate(order.delivery_date),
                  status: getStatusText(order.status),
                }))}
                headers={headers}
                initialRowsPerPage={10}
                onSelectedRowsChange={(selectedRows) => console.log("Selected Rows: ", selectedRows)}
                dataName={"ordenes"}
                enableRowClick={true}
                onRowClick={handleRowClick}  // Pasamos la función que maneja el clic en la fila
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllOrders;

import React, { useEffect, useState } from "react";
import { useHeader } from "../../../../contexts/HeaderContext";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthProvider";
import './form-2.css';

function Login() {
    const { setHeaderTitle } = useHeader();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const auth = useAuth();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const navigate = useNavigate();

    useEffect(() => {
        setHeaderTitle("Plataforma de revendedores");
    }, [setHeaderTitle]);

    useEffect(() => {
        if (auth.isLoggedIn) {
            navigate('/home'); // Redirigir una vez que se haya autenticado
        }
    }, [auth.isLoggedIn, navigate]);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.email !== '' && formData.password !== '') {
            try {

                await auth.login(formData);

            } catch (error) {
                // Error message is already handled by the context, no need to do anything here
            }
        }
    };



    return (
        <div className="row layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="widget widget-content-area br-4">
                    <div className="widget-one">
                        <h6>Bienvenido</h6>
                        <p className="mb-0 mt-3">Por favor, identifíquese.</p>
                        <br />
                        <div className="form-container outer">
                            <div className="form-form">
                                <div className="form-form-wrap" style={{ height: 'inherit' }}>
                                    <div className="form-container">
                                        <div className="form-content">
                                            {/* Mostrar mensaje de error desde el contexto */}
                                            {auth.errorMessage && (
                                                <div className="alert alert-danger" role="alert">
                                                    {auth.errorMessage}
                                                </div>
                                            )}

                                            <form onSubmit={handleSubmit} className="text-left">
                                                <div className="form">
                                                    <div id="username-field" className="field-wrapper input">
                                                        <label htmlFor="email">Ingrese su correo electrónico</label>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                        <input
                                                            tabIndex="500"
                                                            id="email"
                                                            name="email"
                                                            type="text"
                                                            className="form-control"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>

                                                    <div id="password-field" className="field-wrapper input mb-2">
                                                        <div tabIndex="-1" className="d-flex justify-content-between">
                                                            <label tabIndex="-1" htmlFor="password">Ingrese su contraseña</label>
                                                        </div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                                        <input
                                                            tabIndex="501"
                                                            id="password"
                                                            name="password"
                                                            type={passwordVisible ? "text" : "password"}
                                                            className="form-control"
                                                            value={formData.password}
                                                            onChange={handleInputChange}
                                                        />
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            id="toggle-password"
                                                            className="feather feather-eye"
                                                            onClick={togglePasswordVisibility}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                            <circle cx="12" cy="12" r="3"></circle>
                                                        </svg>
                                                    </div>

                                                    <div className="d-sm-flex justify-content-between">
                                                        <div className="field-wrapper">
                                                            <button
                                                                tabIndex="502"
                                                                type="submit"
                                                                className="btn btn-info"
                                                                style={{ boxShadow: 'none', fontSize: '19px' }}
                                                                name="action"
                                                                value="user_login"
                                                            >
                                                                Iniciar sesión
                                                            </button>
                                                            <br />
                                                            <center>
                                                                <Link to="/lost-password" className="forgot-pass-link" style={{ marginBottom: 0, marginTop: '10px', color: '#333' }}>
                                                                    ¿Olvidó la contraseña?
                                                                </Link>
                                                            </center>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="hidden" name="callback" value="/index.php/" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;

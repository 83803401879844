import React from "react";
import './TransactionDetail.css';
const TransactionDetail = () => {
    return (
        <div className="row layout-top-spacing">
            <div
                className="col-lg-12 col-md-12 col-12 layout-spacing"
                style={{ paddingBottom: 0 }}
            >
                <div className="row">
                    <div className="col-xl-9">
                        <div className="invoice-container">
                            <div className="invoice-inbox">
                                <div id="ct">
                                    <div className="invoice-00001">
                                        <div className="content-section">
                                            <div className="inv--note">
                                                <div className="row mt-4">
                                                    <div className="col-sm-12 col-12 order-sm-0 order-1">
                                                        <div className="d-flex">
                                                            <div className="col-6">
                                                                <p className="p_status">
                                                                    Transaccion DEP
                                                                    <br />
                                                                    <span>
                                                                        44435716-3e3b-401e-9c06-e6cc0c184ed5_1689693685728
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="col-2">
                                                                <p className="p_status">
                                                                    Tipo
                                                                    <br />
                                                                    <span className="">OR</span>
                                                                </p>
                                                            </div>
                                                            <div className="col-2">
                                                                <p className="p_status">
                                                                    Resultado DEP
                                                                    <br />
                                                                    <span className="">SUCCESS</span>
                                                                </p>
                                                            </div>
                                                            <div className="col-2">
                                                                <p className="p_status">
                                                                    Fecha Transacción
                                                                    <br />
                                                                    <span className="">21/08/2024</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-9 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="widget widget-content-area br-4 mt-3">
                    <div className="widget-one">
                        <div className="row mb-3">
                            <div className="col-lg-3"></div>
                            <div className="col-12">
                                <div style={{ maxHeight: "300px", overflow: "auto" }}>
                                    <h6 style={{ marginBottom: 0 }}>Bulk Enroll Devices API</h6>
                                    <p>21/08/2024 18:07:07</p>
                                    <div className="d-flex mt-2">
                                        <div className="col-6">
                                            <span>Petición</span>
                                            <textarea style={{ width: "100%", height: "88px" }}>
                                                {`{
  "requestContext": {
    "shipTo": "0001420860",
    "langCode": "es",
    "timeZone": "180"
  },
  "transactionId": "STARTECH-20230718-nCcLfzojfCrXw7ZJ",
  "depResellerId": "D6C9E110",
  "orders": [
    {
      "orderNumber": "ABC123",
      "orderDate": "2024-08-20T00:00:00Z",
      "orderType": "OR",
      "customerId": "378868",
      "deliveries": [
        {
          "deliveryNumber": "D1",
          "shipDate": "2024-08-21T00:00:00Z",
          "devices": [
            {
              "deviceId": "SDX3M53BLN73D",
              "assetTag": "iPhone 11 128GB Black"
            }
          ]
        }
      ]
    }
  ]
}`}
                                            </textarea>
                                        </div>
                                        <div className="col-6">
                                            <span>Respuesta</span>
                                            <textarea style={{ width: "100%", height: "88px" }}>
                                                {`{
  "deviceEnrollmentTransactionId": "44435716-3e3b-401e-9c06-e6cc0c184ed5_1689693685728",
  "enrollDevicesResponse": {
    "statusCode": "SUCCESS",
    "statusMessage": "Transaction posted successfully in DEP"
  }
}`}
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="widget widget-content-area br-4 mt-3">
                    <div className="widget-one">
                        <div className="row mb-3">
                            <div className="col-lg-3"></div>
                            <div className="col-12">
                                <div style={{ maxHeight: "300px", overflow: "auto" }}>
                                    <h6 style={{ marginBottom: 0 }}>Check Transaction Status API</h6>
                                    <p>21/08/2024 18:12:07</p>
                                    <div className="d-flex mt-2">
                                        <div className="col-6">
                                            <span>Petición</span>
                                            <textarea style={{ width: "100%", height: "88px" }}>
                                                {`{
  "requestContext": {
    "shipTo": "0001420860",
    "timeZone": "180",
    "langCode": "es"
  },
  "depResellerId": "D6C9E110",
  "deviceEnrollmentTransactionId": "44435716-3e3b-401e-9c06-e6cc0c184ed5_1689693685728"
}`}
                                            </textarea>
                                        </div>
                                        <div className="col-6">
                                            <span>Respuesta</span>
                                            <textarea style={{ width: "100%", height: "88px" }}>
                                                {`{
  "deviceEnrollmentTransactionID": "44435716-3e3b-401e-9c06-e6cc0c184ed5_1689693685728",
  "statusCode": "COMPLETE",
  "orders": [
    {
      "orderNumber": "ABC123",
      "orderPostStatus": "COMPLETE",
      "deliveries": [
        {
          "deliveryNumber": "D1",
          "deliveryPostStatus": "COMPLETE",
          "devices": [
            {
              "devicePostStatus": "COMPLETE",
              "deviceId": "SDX3M53BLN73D"
            }
          ]
        }
      ]
    }
  ],
  "completedOn": "2024-08-21T18:10:48Z",
  "transactionId": "STARTECH-20230718-nCcLfzojfCrXw7ZJ"
}`}
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionDetail;

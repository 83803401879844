import React, { useEffect, useState } from "react";
import Select from "react-select";
import apiClient from "../../../api/apiClient";

function CompanySelect({ value, onChange }) {
    const [companies, setCompanies] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await apiClient.get("/companies/");
                const companyOptions = response.data.map((company) => ({
                    value: company.id,
                    label: company.name,
                    read_level: company.read_level,
                    apple_ship_to_id: company.apple_ship_to_id,
                }));
                setCompanies(companyOptions);
            } catch (err) {
                setError("Error al obtener las compañías");
            }
        };

        fetchCompanies();
    }, []);

    if (error) {
        return <div>{error}</div>;
    }

    // Adaptar el valor recibido: si es un ID, buscar el objeto completo
    const selectedValue = typeof value === 'object' ? value : companies.find((option) => option.value === value) || null;

    return (
        <Select
            value={selectedValue} // Usar el valor seleccionado adaptado
            options={companies}
            onChange={(selectedOption) => onChange(selectedOption, { name: "company_id" })}
            placeholder="Selecciona una empresa"
            isClearable
            isSearchable
        />
    );
}

export default CompanySelect;

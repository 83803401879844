import React, { useState, useMemo, useEffect } from "react";

function Table({
    data = [],
    headers = [],
    rowsPerPageOptions = [5, 10, 15, 20],
    initialRowsPerPage = 10,
    enableCheckbox = false,
    enableRowClick = false,
    onSelectedRowsChange = () => { },
    columnActionsName = 'Acciones',
    actions = [],
    dataName = "",
    onRowClick = () => { }
}) {
    const [filterText, setFilterText] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    // Actualización del estado de las filas seleccionadas
    useEffect(() => {
        onSelectedRowsChange(selectedRows);
    }, [selectedRows, onSelectedRowsChange]);

    // Manejo del cambio de filtro por columna
    const handleFilterChange = (e, column) => {
        const value = e.target.value.toLowerCase();
        setFilterText((prev) => ({ ...prev, [column]: value }));
        setCurrentPage(1); // Reiniciar la página al cambiar el filtro
    };

    // Filtrado de los datos en base a los filtros aplicados
    const filteredData = useMemo(() => {
        return data.filter((item) => {
            return headers.every((header) => {
                const column = header.accessor;
                const filterValue = filterText[column] || "";

                const itemValue = item[column] !== null && item[column] !== undefined
                    ? item[column].toString().toLowerCase()
                    : "";

                return itemValue.includes(filterValue);
            });
        });
    }, [filterText, data, headers]);

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    const startIndex = (currentPage - 1) * rowsPerPage + 1;
    const endIndex = Math.min(currentPage * rowsPerPage, filteredData.length);
    const paginatedData = filteredData.slice(
        startIndex - 1,
        startIndex - 1 + rowsPerPage
    );

    const handleChangePage = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    // Selección de filas individuales
    const handleSelectRow = (row) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.some((selectedRow) => selectedRow.id === row.id)) {
                return prevSelectedRows.filter((selectedRow) => selectedRow.id !== row.id);
            } else {
                return [...prevSelectedRows, row];
            }
        });
        setSelectAll(false);
    };

    // Selección de todas las filas visibles o filtradas
    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(filteredData);
        }
        setSelectAll(!selectAll);
    };

    // Actualiza el estado del checkbox de "seleccionar todas"
    useEffect(() => {
        const allSelected =
            filteredData.length > 0 &&
            filteredData.every((row) =>
                selectedRows.some((selectedRow) => selectedRow.id === row.id)
            );
        setSelectAll(allSelected);
    }, [filteredData, selectedRows]);

    // Agregar dinámicamente el header de acciones si hay acciones
    const finalHeaders = useMemo(() => {
        if (actions.length > 0) {
            return [...headers, { accessor: "actions", label: columnActionsName }];
        }
        return headers;
    }, [headers, actions, columnActionsName]);

    return (
        <div className="table-responsive">
                    <table className="table table-striped">
                <thead>
                    <tr>
                        {enableCheckbox && (
                            <th>
                                <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                            </th>
                        )}
                        {finalHeaders.map((header, index) => (
                            <th key={`${header.accessor}-${index}`}>{header.label}</th>
                        ))}
                    </tr>
                    <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                        {enableCheckbox && <th></th>}
                        {finalHeaders.map((header, index) => (
                            <th key={`filter-${header.accessor}-${index}`}>
                                {header.accessor !== "actions" && header.searchable && (
                                    <input
                                        type="text"
                                        placeholder={`Buscar ${header.label}`}
                                        className="form-control input-search"
                                        onChange={(e) => handleFilterChange(e, header.accessor)}
                                        value={filterText[header.accessor] || ""}
                                    />
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.map((row) => (
                        <tr
                            key={row.id}
                            onClick={enableRowClick ? () => onRowClick(row) : undefined} // Condiciona el click de la fila
                            style={{ cursor: enableRowClick ? "pointer" : "default" }} // Cambia el cursor según `enableRowClick`
                        >
                            {enableCheckbox && (
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.some((selectedRow) => selectedRow.id === row.id)}
                                        onChange={() => handleSelectRow(row)}
                                        onClick={(e) => e.stopPropagation()} // Evita que el click del checkbox active el click de la fila
                                    />
                                </td>
                            )}
                            {finalHeaders.map((header, index) => (
                                <td key={`${header.accessor}-${row.id}-${index}`}>
                                    {header.accessor === "actions" ? (
                                        <>
                                            {actions.map((action, idx) => (
                                                <span key={`action-${idx}`}>
                                                    {action.render ? (
                                                        action.render(row)
                                                    ) : (
                                                        <button
                                                            className={`btn ${action.className || "btn-primary"} ml-2`}
                                                            onClick={(e) => {
                                                                if (e && e.stopPropagation) {
                                                                    e.stopPropagation(); // Evitar que el click del botón active el click de la fila
                                                                }
                                                                action.onClick(row);
                                                            }}
                                                        >
                                                            {action.label}
                                                        </button>
                                                    )}
                                                </span>
                                            ))}
                                        </>
                                    ) : (
                                        row[header.accessor] !== null && row[header.accessor] !== undefined ? row[header.accessor] : ""
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <label>{dataName.charAt(0).toUpperCase() + dataName.slice(1).toLowerCase()} por página:</label>
                    <select
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        className="form-control"
                        style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
                    >
                        {rowsPerPageOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <button
                        className="boton-limpio"
                        onClick={() => handleChangePage(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Anterior
                    </button>

                    {currentPage > 1 && (
                        <button
                            className="boton-limpio"
                            onClick={() => handleChangePage(currentPage - 1)}
                        >
                            {currentPage - 1}
                        </button>
                    )}
                    <button
                        className="btn btn-primary"
                        onClick={() => handleChangePage(currentPage)}
                        disabled
                    >
                        {currentPage}
                    </button>
                    {currentPage < totalPages && (
                        <button
                            className="boton-limpio"
                            onClick={() => handleChangePage(currentPage + 1)}
                        >
                            {currentPage + 1}
                        </button>
                    )}

                    <button
                        className="boton-limpio"
                        onClick={() => handleChangePage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Siguiente
                    </button>
                </div>
            </div>

            <div className="text-muted mt-2">
                Viendo desde {startIndex} hasta {endIndex} de {filteredData.length} {dataName}
            </div>
        </div>
    );
}

export default Table;

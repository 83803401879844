import React, { useMemo, useEffect, useState, useCallback } from "react";
import { useHeader } from "../../../../contexts/HeaderContext";
import { Modal } from 'react-bootstrap';
import apiClient from "../../../../api/apiClient";
import Swal from 'sweetalert2';
import './PendingApprovals.css';
import Table from "../../../../components/Table/Table";
import { useAuth } from '../../../../contexts/AuthProvider';

function PendingApprovals() {
    const { userInfo } = useAuth();
    console.log(userInfo);
    const { setHeaderTitle } = useHeader();
    const [showModal, setShowModal] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        setHeaderTitle("Gestión de revendedores");
    }, [setHeaderTitle]);

    const backendUrl = process.env.REACT_APP_BACKEND_STORAGE;

    // Definimos la función `fetchPendingApprovals` como `useCallback` para poder reutilizarla sin crear una nueva instancia cada vez.
    const fetchPendingApprovals = useCallback(async () => {
        try {
            const response = await apiClient.get('/companies', {
                params: {
                    filter: { pending_auth: 1 },
                },
            });
            setData(response.data);
        } catch (error) {
            console.error('Error al obtener aprobaciones pendientes:', error.response ? error.response.data : error.message);
        }
    }, []);

    // Llamamos a `fetchPendingApprovals` una vez cuando el componente se monta
    useEffect(() => {
        fetchPendingApprovals();
    }, [fetchPendingApprovals]);

    const headers = useMemo(() => [
        { label: "Nombre", accessor: "name", searchable: true },
        { label: "Apple Reseller ID", accessor: "apple_reseller_id", searchable: true },
    ], []);

    const visualizarCaptura = (row) => {
        setCurrentImage(row.path_print);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const handleApprove = async () => {
        if (selectedRows.length === 0) {
            Swal.fire(
                '¡Error!',
                'Por favor selecciona al menos un revendedor para aprobar.',
                'error'
            );
            return;
        }
        const idsToApprove = selectedRows.map(row => parseInt(row.id, 10));
        let title = '¿Aprobar revendedores?';
        if (selectedRows.length === 1) {
            const resellerName = selectedRows[0].name;
            title = `¿Aprobar a ${resellerName}?`;
        }

        Swal.fire({
            title: title,
            text: selectedRows.length === 1 ? "Este revendedor podrá comenzar a enrolar dispositivos." : "Podrán comenzar a enrolar dispositivos.",
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No',
            customClass: {
                confirmButton: 'approve-confirm-custom',
                cancelButton: 'approve-cancel-custom'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await apiClient.put('/updated-multiple-companies', { ids_companies: idsToApprove, pending_auth: 0, can_enroll: 1 });
                    Swal.fire(
                        '¡Aprobado!',
                        'Los revendedores seleccionados han sido aprobados.',
                        'success'
                    );
                    // Actualizar la tabla después de aprobar
                    fetchPendingApprovals();
                } catch (error) {
                    console.error('Error al aprobar:', error.response ? error.response.data : error.message);
                    Swal.fire(
                        '¡Error!',
                        'Hubo un problema al aprobar los revendedores seleccionados.',
                        'error'
                    );
                }
            }
        });
    };

    const handleReject = async () => {
        if (selectedRows.length === 0) {
            Swal.fire(
                '¡Error!',
                'Por favor selecciona al menos un revendedor para rechazar.',
                'error'
            );
            return;
        }
        const idsToReject = selectedRows.map(row => parseInt(row.id, 10));
        let title = '¿Desaprobar revendedores?';
        if (selectedRows.length === 1) {
            const resellerName = selectedRows[0].name;
            title = `¿Desaprobar a ${resellerName}?`;
        }

        Swal.fire({
            title: title,
            text: selectedRows.length === 1 ? "Este revendedor no podrá enrolar dispositivos." : "No podrán enrolar dispositivos.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No',
            customClass: {
                confirmButton: 'disapprove-confirm-custom',
                cancelButton: 'disapprove-cancel-custom'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await apiClient.put('/updated-multiple-companies', { ids_companies: idsToReject, pending_auth: 0, can_enroll: 0 });
                    Swal.fire(
                        '¡Rechazado!',
                        'Los revendedores seleccionados han sido rechazados.',
                        'success'
                    );
                    // Actualizar la tabla después de rechazar
                    fetchPendingApprovals();
                } catch (error) {
                    console.error('Error al rechazar:', error.response ? error.response.data : error.message);
                    Swal.fire(
                        '¡Error!',
                        'Hubo un problema al rechazar los revendedores seleccionados.',
                        'error'
                    );
                }
            }
        });
    };

    return (
        <div>
            <div className="row layout-top-spacing">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <h5 className="widget-title">Aprobaciones pendientes</h5>

                            {/* Componente Table */}
                            <Table
                                data={data} // Los datos de los revendedores
                                headers={headers} // Encabezados
                                enableCheckbox={userInfo.role.execute}// Habilita la selección de filas
                                initialRowsPerPage={10} // Número de filas por página
                                onSelectedRowsChange={(newSelectedRows) => setSelectedRows(newSelectedRows)} // Maneja las filas seleccionadas
                                columnActionsName={'Captura de pantalla'}
                                actions={[
                                    {
                                        label: "Visualizar",
                                        className: "btn-primary btn-blue",
                                        onClick: visualizarCaptura // Muestra la captura de pantalla
                                    }
                                ]}
                                dataName={"aprobaciones pendientes"}
                            />

                            {userInfo.role.execute && (
                                <div className="row d-flex mt-3">
                                    <div className="col">
                                        <button className="btn btn-success btn-approve" onClick={handleApprove}>Aprobar los revendedores seleccionados</button>
                                        <button className="btn btn-danger btn-reject" style={{ marginLeft: "10px" }} onClick={handleReject}>Rechazar los revendedores seleccionados</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal para visualizar la captura de pantalla */}
            <Modal show={showModal} onHide={handleCloseModal} size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Visualizando captura de pantalla</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={`${backendUrl}${currentImage}`} alt="Captura de pantalla" className="img-fluid" />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default PendingApprovals;

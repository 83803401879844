import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import {jwtDecode} from 'jwt-decode';
import apiClient, { configureApiClient } from '../api/apiClient';
import { LoadingContext } from './LoadingContext'; // Usar LoadingContext

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const { showLoader, hideLoader } = useContext(LoadingContext); // Usar el contexto del loader

    // Función de logout
    const logout = useCallback(() => {
        localStorage.removeItem('token');
        setAuthToken(null);
        setUserInfo(null);
        setIsLoggedIn(false);
        setErrorMessage('Sesión cerrada. Por favor, inicia sesión nuevamente.');
    }, []);

    // Configurar el apiClient con las funciones de loader y logout
    useEffect(() => {
        configureApiClient(showLoader, hideLoader, logout); // Pasar las funciones al apiClient
    }, [showLoader, hideLoader, logout]);

    // Verificar si el token es válido
    useEffect(() => {
        const checkAuthToken = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    const currentTime = Date.now() / 1000;
                    if (decodedToken.exp > currentTime) {
                        setAuthToken(token);
                        setUserInfo({
                            id: decodedToken.id,
                            full_name: decodedToken.full_name,
                            role: decodedToken.role,
                            company: decodedToken.company,
                        });
                        setIsLoggedIn(true);
                    } else {
                        localStorage.removeItem('token');
                    }
                } catch (error) {
                    console.error('Error al decodificar el token', error);
                }
            }
            setLoading(false); // Marcar como cargado
        };

        checkAuthToken();
    }, []);

    const login = async (data) => {
        try {
            showLoader(); // Mostrar loader al iniciar el login
            const response = await apiClient.post('/login', data);
            const token = response.data.token;

            if (token) {
                localStorage.setItem('token', token);
                const decoded = jwtDecode(token);
                const currentTime = Date.now() / 1000;

                if (decoded.exp > currentTime) {
                    setAuthToken(token);
                    setUserInfo({
                        id: decoded.id,
                        full_name: decoded.full_name,
                        role: decoded.role,
                        company: decoded.company,
                    });
                    setIsLoggedIn(true);
                    setErrorMessage('');
                } else {
                    localStorage.removeItem('token');
                    setIsLoggedIn(false);
                    setErrorMessage('El token ha expirado, por favor inicia sesión nuevamente.');
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrorMessage('Credenciales incorrectas, por favor intenta nuevamente.');
            } else if (error.response && error.response.status === 500) {
                setErrorMessage('Error en el servidor. Intente más tarde.');
            } else {
                setErrorMessage('Ocurrió un error inesperado. Intente de nuevo.');
            }
        } finally {
            hideLoader(); // Ocultar el loader al finalizar la petición
        }
    };

    if (loading) {
        return <div>Cargando...</div>;
    }

    return (
        <AuthContext.Provider value={{ authToken, isLoggedIn, userInfo, login, logout, loading, errorMessage }}>
            {children}
        </AuthContext.Provider>
    );
};

// Hook para usar el contexto fácilmente en cualquier componente
export const useAuth = () => {
    return useContext(AuthContext);
};

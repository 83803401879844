import React from "react";
import { Link } from "react-router-dom";
import Badge from "../../../../../../components/Badge/Badge";
import { formatDate } from "../../../../../../utils/dates";

const TransactionTable = ({ transactions = [], orderId }) => {
    if (transactions.length === 0) {
        return <div>No hay transacciones disponibles</div>;
    }

    return (
        <div className="row">
            <div className="col-xl-9">
                <div className="invoice-container">
                    <div className="invoice-inbox">
                        <div id="ct">
                            <div className="invoice-00001">
                                <div className="content-section">
                                    <div className="inv--note">
                                        <div className="row mt-4">
                                            <div className="col-sm-12 col-12 order-sm-0 order-1">
                                                <table
                                                    className="table table-bordered table-striped"
                                                    width="100%"
                                                    border="0"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th className="text-dark">DEP Transaction ID</th>
                                                            <th className="text-dark">Tipo</th>
                                                            <th className="text-dark">Resultado</th>
                                                            <th className="text-dark">F. Envío</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transactions.map((transaction, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <Link to={`/enrollment/${orderId}/transaction-detail/${transaction.id}`}>
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            className="feather feather-search"
                                                                        >
                                                                            <circle cx="11" cy="11" r="8"></circle>
                                                                            <line
                                                                                x1="21"
                                                                                y1="21"
                                                                                x2="16.65"
                                                                                y2="16.65"
                                                                            ></line>
                                                                        </svg>

                                                                        {transaction.dep_transaction_id}
                                                                    </Link>
                                                                </td>
                                                                <td>{transaction.transaction_type}</td>
                                                                <td>
                                                                    <Badge status={transaction.dep_transaction_status} />
                                                                </td>
                                                                <td>{formatDate(transaction.created_at)}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionTable;
